.root {
  @apply fixed flex items-baseline inset-0 z-50 justify-center;
  backdrop-filter: blur(0.8px);
  -webkit-backdrop-filter: blur(0.8px);
  z-index: 999999!important;
}

.modal {
  @apply p-0 border border-accent-2 relative;
}

.modal:focus {
  @apply outline-none;
}

.close {
  @apply hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none absolute right-0 top-0 m-6;
}
